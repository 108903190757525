<template>
    <div class="contacts w-100 black">
      <div class="w-100 d-flex justify-content-center black">
        <v-form id="policy-form" class="mt-lg-16" style="display: block; margin: auto;">
          <div class="form-content">
            <v-btn text fab @click="$emit('close')" style="float: right;" class="d-none d-sm-block"><v-icon
                large>mdi-close</v-icon></v-btn>
            <v-btn text fab @click="$emit('close')" style="top: -60px; right: -30px; position: absolute;"
              class="d-sm-none"><v-icon large>mdi-close</v-icon></v-btn>
            <v-row class="policy-content" @scroll="onScroll">
              <v-col cols="12" class="pb-16 mb-16">
                <div class='intro mt-2'>
                  <p class='w-100 text-center'>
                    <strong>
                      POLÍTICA DE PRIVACIDADE
                    </strong>
                  </p>
                  <div>
                    <v-card class="elevation-0 transparent">
                      <v-card-text>
                        <v-row class="text-body-1" no-gutters>
                          <v-col cols="12">
                            <span class="white--text font-weight-bold">Responsável de Tratamento:</span> Dreamdeal
                            Technologies, Unipessoal, Lda.
                          </v-col>
                          <v-col cols="12" class="mt-1">
                            <span class="white--text font-weight-bold">NIF:</span> 516178733
                          </v-col>
                          <v-col cols="12" class="mt-1">
                            <span class="white--text font-weight-bold">Morada:</span> Rua Alfredo Keil, 371, 3E, 4150-048
                            Porto
                          </v-col>
                          <v-col cols="12" class="mt-1">
                            <span class="white--text font-weight-bold">E-mail:</span> data.protection@ignoto.pt
                          </v-col>
                          <v-col cols="12" class="mt-2">
                            <v-divider color="white"></v-divider>
                          </v-col>
                          <v-col cols="12" class="mt-2">
                            <span class="white--text font-weight-bold">Encarregado de Proteção de Dados:</span> Dep. de
                            Compliance da IGNOTO
                          </v-col>
                          <v-col cols="12" class="mt-1">
                            <span class="white--text font-weight-bold">E-mail:</span> data.protection@ignoto.pt
                          </v-col>
                          <v-col cols="12" class="mt-1">
                            <span class="white--text font-weight-bold">Telefone:</span> 220280430
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class='index mt-5'>
                    <ol type="A">
                      <li><a href='#introduction'>Introdução</a></li>
                      <li><a href='#principios'>Princípios relacionados com o tratamento de dados pessoais</a></li>
                      <li><a href='#categorias'>Categorias de dados pessoais recolhidos</a></li>
                      <li><a href='#tratamento'>Como tratamos a informação que recolhemos e recebemos</a></li>
                      <li><a href='#conteudos'>Conteúdos e serviços ligados (links) via site www.ignoto.pt</a></li>
                      <li><a href='#periodo'>Período de conservação</a></li>
                      <li><a href='#bases'>Bases de Legitimidade para tratamento de dados pessoais</a></li>
                      <li><a href='#direitos'>Os seus direitos</a></li>
                      <li><a href='#seguranca'>Segurança e confidencialidade</a></li>
                      <li><a href='#partilha'>Partilha de informação</a></li>
                      <li><a href='#localizacao'>Localização e transferências</a></li>
                      <li><a href='#reclamacao'>Apresentar uma reclamação junto da autoridade de controlo</a></li>
                      <li><a href='#atualizacoes'>Atualizações à Nota de Privacidade deste website</a></li>
                      <li><a href='#contactos'>Contactos</a></li>
                    </ol>
                  </div>
                </div>
                <div id="introduction">
                  <v-card class="transparent">
                    <v-card-title>A. Introdução</v-card-title>
                    <v-card-text>
                      Esta Nota de Privacidade visa informar os visitantes do website e utilizadores do serviço
                      (“Utilizadores”, “titulares dos dados”, “clientes”, “seu”) acerca das regras e procedimentos
                      adotados
                      pela IGNOTO (“IGNOTO”, "nós", “nos”, "nosso") em relação ao tratamento dos dados que
                      recolhemos quando acede ao website <a href="www.ignoto.pt" target="_blank">www.ignoto.pt</a> e usa
                      as funcionalidades e serviços
                      (coletivamente, os nossos “Serviços”) que este oferece.<br /><br />
                      Esta política aplica-se quando estamos a agir enquanto responsáveis de tratamento dos dados em
                      relação aos seus dados pessoais, ou seja, quando determinamos as finalidades e meios do
                      tratamento dos dados pessoais.<br /><br />
                      Estamos conscientes da importância vital em proteger os seus dados pessoais e, por isso,
                      desenvolvemos uma série de medidas de segurança técnicas e organizacionais.<br /><br />
                      O nosso site incorpora controlos de privacidade que afetam a forma como tratamos os seus dados
                      pessoais. Ao usar os controlos de privacidade, pode especificar se deseja receber newsletters sobre
                      campanhas de marketing, notificações por e-mail e mensagens de texto e limitar a visibilidade das
                      suas informações. O utilizador pode aceder aos controlos de privacidade através do endereço web:
                      <a href="https://ignoto.pt/settings" target="_blank">https://ignoto.pt/settings</a><br /><br />
                      Ao aceder e usar os nossos Serviços, o utilizador será solicitado a consentir a recolha e
                      subsequente
                      tratamento dos seus dados pessoais. Qualquer informação que nos fornecer ao usar nossos
                      Serviços, e pelas quais possa ser identificado enquanto titular dos dados, será usada somente de
                      acordo com esta Nota de Privacidade e para as finalidades específicas aqui indicadas, com vigência
                      a partir de 29.04.2024. Deve ler atentamente este documento e aceder somente ao nosso website e
                      / ou enviar informações, se concordar com suas disposições.<br /><br />
                      Esta Nota de Privacidade cobre apenas o tratamento de dados realizado pela IGNOTO. A Nota de
                      Privacidade não engloba e não nos responsabilizamos pelas práticas de privacidade de terceiros,
                      também nos casos em que os Serviços incluam hiperlinks para sites de terceiros ou quando os
                      cookies forem definidos por terceiros.<br /><br />
                      <b>Não recolhemos intencionalmente informações pessoais de nenhuma pessoa com idade
                        inferior a 18 anos nem permitimos que se registem ou utilizem os nossos Serviços. Se formos
                        informados de que recolhemos dados pessoais de uma pessoa com menos de 18 anos,
                        excluiremos essas informações imediatamente. Se tiver motivos para acreditar que isso tenha
                        ocorrido, envie um e-mail para data.protection@ignoto.pt e excluiremos todas as informações
                        relevantes.</b><br /><br />
                    </v-card-text>
                  </v-card>
                </div>
                <div id="principios">
                  <v-card class="transparent">
                    <v-card-title>B. Princípios relacionados com o tratamento de dados pessoais</v-card-title>
                    <v-card-text>
                      Estamos comprometidos em salvaguardar a sua privacidade e em tratar os seus dados pessoais de
                      forma lícita, justa e transparente.<br /><br />
                      Recolhemos os seus dados pessoais para fins específicos, explícitos e legítimos explicados abaixo
                      e não tratamos os seus dados de maneira incompatível com esses propósitos.<br /><br />
                      Tomamos todas as medidas razoáveis para garantir que os dados que recolhemos e tratamos de
                      qualquer outra forma sejam precisos, completos, atualizados, necessários e limitados aos fins para
                      os quais são tratados e sejam eliminados ou retificados sem demora quando os fins específicos são
                      cumpridos. Para manter os dados pessoais atualizados, esperamos que o utilizador faça as
                      atualizações necessárias na opção de “informações pessoais” através do endereço web:
                      <a href="https://ignoto.pt/settings" target="_blank">https://ignoto.pt/settings</a><br /><br />
                      Não armazenamos os seus dados pessoais em formulários que permitam a identificação dos titulares
                      dos dados por mais tempo do que o permitido legalmente e necessário para as finalidades para as
                      quais os dados pessoais são tratados.<br /><br />
                      Tratamos os seus dados com medidas de segurança, confidencialidade e integridade adequadas,
                      incluindo proteção contra tratamento não autorizado ou ilegal e contra perda, destruição ou danos
                      acidentais, usando medidas técnicas ou organizacionais apropriadas. Essas medidas incluem a
                      manutenção de um sistema de salvaguardas administrativas, físicas e técnicas para proteger os
                      dados pessoais.<br /><br />
                    </v-card-text>
                  </v-card>
                </div>
                <div id="categorias">
                  <v-card class="transparent">
                    <v-card-title>C. Categorias de dados pessoais recolhidos</v-card-title>
                    <v-card-text>
                      Quando usa os nossos Serviços, recolhemos categorias especificas de dados pessoais dependendo
                      dos Serviços que usa.<br />
                      Podemos recolher e tratar dados pessoais nas seguintes categorias:<br /><br />
                      <b>Dados de Comunicação:</b> Se subscrever a nossa newsletter, a qual contém informação sobre os
                      produtos e serviços disponíveis no nosso site, ser-lhe-á solicitado o seu endereço de e-mail para o
                      envio da mesma e que ficará guardado num servidor de terceiros, um prestador de serviços de e-mail
                      marketing (MailJet);<br /><br />
                      <b>Dados de Registo:</b> Para usar certos recursos da IGNOTO, necessitará de abrir uma conta
                      Cliente.
                      Assim, no momento de registo, recolhemos dados pessoais de si e da sua empresa, incluindo o seu
                      nome, apelido, função, email e número de telefone. Da empresa recolhemos a denominação social,
                      a morada incluindo o código e postal, localidade e país, assim como o NIF e o email para onde será
                      enviada a fatura do serviço contratado.<br /><br />
                      <b>Dados de Conexão a Redes Sociais:</b> Se se registar na IGNOTO usando credenciais de um
                      aplicativo, site ou serviço de terceiros, como o Google, Facebook ou o LinkedIn, estará a
                      autorizar-nos a recolher as suas informações de autenticação. Poderá controlar as informações que
                      recebemos do Google, Facebook e do LinkedIn usando as configurações de privacidade das suas
                      contas;<br /><br />
                      <b>Dados Técnicos:</b> Recebemos informações técnicas do Google Analytics, como as páginas
                      visitadas,
                      o navegador que está a utilizar, os tipos de dispositivos que usa para aceder à nossa plataforma e
                      dados demográficos. Também vamos monitorizar a duração de cada página que visita. Embora
                      normalmente não utilizemos dados técnicos para identificar indivíduos, às vezes os indivíduos podem
                      ser reconhecidos a partir deles, isoladamente ou quando combinados ou vinculados a dados do
                      utilizador. Em tais situações, os dados técnicos também podem ser considerados dados pessoais
                      sob a lei aplicável e trataremos os dados combinados como dados pessoais.<br /><br />
                      Utilizamos várias tecnologias para recolher e tratar dados técnicos relacionados consigo, incluindo
                      cookies. Cookies são pequenos arquivos de texto armazenados no computador dos utilizadores pelo
                      navegador da Internet. Na medida em que esses cookies não sejam estritamente necessários para
                      o fornecimento do nosso site e serviços, solicitaremos que autorize o uso de cookies quando visitar
                      o nosso site pela primeira vez. Os cookies permitem-nos calcular o número total de pessoas que
                      usam os nossos Serviços e monitorizar o uso dos nossos Serviços. Isso ajuda-nos a melhorar os
                      nossos serviços e a servir melhor os utilizadores.
                      Também podemos usar cookies que facilitam a utilização dos Serviços, por exemplo, lembrando
                      nomes de utilizadores, senhas e preferências. Podemos usar cookies de rastreamento e análise para
                      ver como os nossos Serviços estão a ser recebidos pelos nossos Utilizadores.
                      Alguns cookies permitem que os nossos Serviços funcionem e nos informam se os nossos Serviços
                      estão a funcionar adequadamente.
                      Os nossos cookies de publicidade permitem-nos entregar publicidade direcionada para as pessoas
                      que visitam os nossos sites (publicidade comportamental on-line). Os utilizadores podem optar por
                      configurar o seu navegador para recusar cookies ou para alertar quando os cookies estão a ser
                      enviados. Geralmente, isso pode ser feito através das configurações do navegador da Internet.
                      Informações sobre como gerenciar cookies podem ser encontradas on-line. Algumas partes dos
                      nossos Serviços podem não funcionar corretamente se optar por não aceitar o uso de cookies. O
                      nosso aviso de cookie fornece mais informações sobre cookies em relação aos nossos serviços em
                      <a href="https://ignoto.pt/cookies" target="_blank">https://ignoto.pt/cookies</a>
                    </v-card-text>
                  </v-card>
                </div>
                <div id="tratamento">
                  <v-card class="transparent">
                    <v-card-title>D. Como tratamos a informação que recolhemos e recebemos</v-card-title>
                    <v-card-text>
                      <b>A nossa comunicação consigo:</b> Tratamos os Dados de Notificação sobre si para várias
                      finalidades
                      relacionadas à comunicação consigo, incluindo:<br /><br />
                      <ul>
                        <li>Para enviar a nossa Newsletter para si via MailJet;</li>
                        <li>Para comunicar consigo, de acordo com as configurações da sua conta, sobre produtos e
                          serviços.</li>
                      </ul><br />
                      <b>Autenticação:</b> Trataremos os seus Dados de Registo para verificar e identificá-lo quando fizer
                      login
                      na IGNOTO e para operacionalizar a sua conta IGNOTO, impedindo qualquer atividade ilegal ou não
                      autorizada<br /><br />
                      <b>Operacionalizando a nossa plataforma e melhorando a experiência do utilizador:</b> Tratamos
                      Dados de Perfil, Dados da Empresa e Dados de Redes Sociais Conectadas sobre si para diversas
                      finalidades relacionadas à operação da nossa plataforma, incluindo:<br />
                      <ul><br />
                        <li>fornecer, manter e melhorar a nossa plataforma, inclusivé para processar transações,
                          desenvolver novos produtos e serviços, simplificar o processamento de futuras compras de
                          serviços e gerir o desempenho da nossa plataforma;</li>
                        <li>fornecer os nossos serviços de acordo com os Termos de Serviço.</li>
                      </ul><br />
                      <b>Processamento de pedidos de compra:</b> Tratamos os seus dados de registo e dados da empresa
                      para fornecer serviços de pagamento de acordo com os nossos Termos de Serviço e para a gestão
                      de contratos.<br /><br />
                      <b>Otimização da experiência do utilizador:</b> Tratamos os seus dados de registo, dados da empresa,
                      dados de utilização contínuos, dados de perfil, dados de redes sociais conectadas e dados técnicos
                      recebidos para fins estatísticos e para personalizar e otimizar a sua experiência no site da IGNOTO
                      e usar os nossos serviços, incluindo para:<br />
                      <ul>
                        <li>monitorizar e analisar tendências, utilização, e atividades em conexão com a nossa
                          plataforma;</li>
                        <li>para melhorar os nossos Serviços e o seu uso;</li>
                        <li>efetuar rastreabilidade do uso dos nossos Serviços.</li>
                      </ul><br />
                      <b>Requisitos Legais:</b> Também poderemos tratar informações para cumprir uma obrigatoriedade legal
                      ou executar um contrato.<br /><br />
                      Deve observar que os dados pessoais obrigatórios solicitados são necessários para fornecer os
                      Serviços solicitados por meio deste site e para cumprir as obrigações decorrentes da lei e dos
                      regulamentos aplicáveis.<br /><br />
                      A divulgação de seus dados além do necessário para fornecer os Serviços solicitados por si ou para
                      cumprir os requisitos legais ou contratuais é opcional e não tem efeito sobre o uso deste site.
                      Informaremos se o fornecimento dos seus dados pessoais é obrigatório ou opcional, colocando um
                      asterisco (*) junto a informações obrigatórias.<br /><br />
                      Consequentemente, qualquer recusa em nos fornecer os dados pessoais necessários pode impedir
                      o processamento da sua ordem de compra do serviço. Reservamo-nos o direito de recusar o
                      processamento de seu pedido de compra de serviços, se não nos fornecer os dados pessoais
                      necessários. O não fornecimento de dados opcionais não será penalizado e não impede o
                      processamento dos Serviços.
                    </v-card-text>
                  </v-card>
                </div>
                <div id="conteudos">
                  <v-card class="transparent">
                    <v-card-title>E. Conteúdos e serviços ligados (links) via site &nbsp;<a href="www.ignoto.pt"
                        target="_blank">www.ignoto.pt</a></v-card-title>
                    <v-card-text>
                      Este site pode conter ligações (links), diretórios e ferramentas de busca que permitem aceder a
                      sites
                      de entidades externas que não são controladas pela IGNOTO. Nós não somos responsáveis e não
                      endossamos nenhum site vinculado. Os sites vinculados podem ter os seus próprios termos de uso e
                      políticas de privacidade que regem o acesso e uso dos mesmos, dos quais a IGNOTO não tem
                      conhecimento.<br /><br />
                      Não somos responsáveis por qualquer perda, dano ou obrigação incorridos como resultado da visita
                      a um site vinculado a este site, e a IGNOTO não está associada a nenhum dos sites mencionados.
                      Se acedeu a este site por meio de uma ligação (link) externa, o seu acesso e uso deste site são
                      regidos por estes termos e por esta política de privacidade, e não pelos termos e políticas da
                      entidade
                      externa a partir da qual se ligou.
                    </v-card-text>
                  </v-card>
                </div>
                <div id="periodo">
                  <v-card class="transparent">
                    <v-card-title>F. Período de conservação</v-card-title>
                    <v-card-text>
                      Não conservamos os seus dados pessoais por mais tempo do que o permitido legalmente e
                      necessário para os fins de tratamento relacionados. O período de conservação depende do tipo de
                      dados pessoais, dos objetivos e da lei aplicável e, portanto, varia de acordo com o seu
                      uso.<br /><br />
                      Normalmente, conservamos os seus dados pessoais enquanto estiver a usar os nossos Serviços ou
                      enquanto tivermos <u>outro</u> propósito para fazê-lo e, a partir de então, por não mais do que o
                      exigido ou
                      permitido por lei ou necessário para fins internos de elaboração de relatórios e
                      reconciliação.<br /><br />
                      Eliminaremos os seus dados pessoais após o período de conservação descrito acima ou quando nos
                      solicitar que o façamos, desde que não exista nenhum impedimento legal ou contratual referente aos
                      mesmos.
                    </v-card-text>
                  </v-card>
                </div>
                <div id="bases">
                  <v-card class="transparent">
                    <v-card-title>G. Bases de Legitimidade para tratamento de dados pessoais</v-card-title>
                    <v-card-text>
                      Tratamos dados pessoais na persecução do nosso legítimo interesse em administrar, manter e
                      desenvolver nossos negócios. Além disso, tratamos dados pessoais para cumprir com as nossas
                      obrigações legais.<br /><br />
                      Em algumas partes de nossos Serviços, podemos solicitar que nos autorize o tratamento dos seus
                      dados pessoais para fins específicos. Nesse caso, poderá retirar o seu consentimento a qualquer
                      momento
                    </v-card-text>
                  </v-card>
                </div>
                <div id="direitos">
                  <v-card class="transparent">
                    <v-card-title>H. Os seus direitos</v-card-title>
                    <v-card-text>
                      <b>Direito de acesso:</b> o titular dos dados pode entrar em contato conosco para confirmar se
                      estamos
                      ou não a tratar os seus dados pessoais. Nos casos em que tratamos os seus dados pessoais, iremos
                      informá-lo sobre quais as categorias de dados pessoais que tratamos em relação a si, as finalidades
                      de tratamento, as categorias de destinatários a quem os dados pessoais foram ou serão divulgados
                      e o período ou critérios de conservação previstos para determinar esse período.<br /><br />
                      <b>Direito de retirar o consentimento:</b> caso o nosso tratamento seja baseado em consentimento
                      concedido pelo titular dos dados, poderá retirar o consentimento a qualquer momento entrando em
                      contato conosco ou usando as funcionalidades dos nossos Serviços. Retirar um consentimento pode
                      levar a menos possibilidades de uso dos nossos Serviços.<br /><br />
                      <b>Direito de retificação:</b> o titular dos dados tem o direito de ter os dados pessoais imprecisos
                      ou
                      incompletos que conservamos sobre si retificados ou completos<br /><br />
                      <b>Direito de oposição:</b> caso o tratamento dos dados pessoais seja baseado no interesse legítimo
                      da
                      IGNOTO em administrar, manter e desenvolver os negócios, o titular tem o direito de se opôr a
                      qualquer momento ao tratamento. Neste caso, não poderemos continuar a tratar os seus dados
                      pessoais a menos que para a prestação dos nossos Serviços ou se demonstrarmos outros motivos
                      legítimos para o nosso tratamento que anulem os seus interesses, direitos e liberdades ou para
                      reivindicações legais. Não obstante qualquer consentimento concedido antecipadamente para fins
                      de marketing direto, o titular tem o direito de nos proibir de usar os seus dados pessoais para fins
                      de
                      marketing direto, contactando-nos ou usando as funcionalidades dos Serviços ou cancelando as
                      possibilidades relacionadas às nossas mensagens de marketing direto.<br /><br />
                      <b>Direito de limitação:</b> o titular dos dados tem o direito de obter de nós limitação ao
                      tratamento dos
                      seus dados pessoais, conforme previsto pela lei de proteção de dados aplicável. A limitação de
                      tratamento pode levar a menos possibilidades de usar os nossos serviços.<br /><br />
                      <b>Direito à portabilidade de dados:</b> o titular dos dados tem o direito de receber seus dados
                      pessoais
                      num formato estruturado, comumente usado e legível por meios eletrónicos e transmitir esses dados
                      de forma independente a terceiros, caso o nosso tratamento seja baseado no seu consentimento e
                      realizado por meios automatizados.<br /><br />
                      <b>Direito de apagamento:</b> o titular dos dados tem o direito de solicitar que os seus dados
                      pessoais
                      sejam apagados dos nossos sistemas, se os dados pessoais não forem mais necessários para as
                      finalidades relacionadas, se tivermos tratado ilegalmente os mesmos ou se o utilizador se opuser ao
                      tratamento de marketing direto, a menos que tenhamos um motivo legítimo para não apagar os seus
                      dados. Podemos não ser capazes de apagar imediatamente todas as cópias residuais dos nossos
                      servidores e sistemas de backup após os dados ativos terem sido apagados. Tais cópias serão
                      apagadas assim que razoavelmente possível.<br /><br />
                      Para exercer os seus direitos livremente e a qualquer momento, de acordo com a lei e os
                      regulamentos aplicáveis, envie a sua solicitação para nós para: data.protection@ignoto.pt
                    </v-card-text>
                  </v-card>
                </div>
                <div id="seguranca">
                  <v-card class="transparent">
                    <v-card-title>I. Segurança e confidencialidade</v-card-title>
                    <v-card-text>
                      Nós, enquanto entidade responsável pela gestão do nosso site, envidamos os melhores esforços
                      para garantir a privacidade dos dados pessoais que recolhemos e transmitimos on-line. A segurança
                      e a privacidade dos dados recolhidos são de extrema importância para nós. Nesse sentido, usamos
                      tecnologias e procedimentos de segurança para proteger as informações recolhidas contra acesso,
                      uso ou divulgação não autorizados. Nenhuma medida de segurança, no entanto, pode ser
                      completamente segura, assim, não podemos garantir que essas medidas impedirão que alguém
                      tente contornar a privacidade ou os procedimentos de segurança através de meios imprevistos e /
                      ou ilegais.<br /><br />
                      O acesso aos dados recolhidos ou recebidos é limitado aos funcionários da IGNOTO e quaisquer
                      subcontratados e terceiros que exijam acesso aos seus dados pessoais para fornecer os Serviços
                      solicitados por si.<br /><br />
                      O acesso aos seus dados pessoais será fornecido aos órgãos públicos ou autoridades públicas,
                      apenas quando isso for necessário para cumprir uma obrigação legal ou contratual da IGNOTO, ou seja,
                      de acordo com uma ordem judicial ou uma ordem administrativa, desde que a mesma seja
                      emitida por uma autoridade competente.<br /><br />
                      Deve no entanto estar ciente de que os dados recolhidos no site podem, ao serem recolhidos, estar
                      disponíveis e não seguros na rede, de modo que haja um risco de que possam ser visualizados e
                      usados por terceiros não autorizados.<br /><br />
                      Caso acreditemos que a segurança das suas informações pessoais em nossa posse ou controlo
                      tenha sido comprometida ou possa estar em risco, procuraremos notificá-lo o mais rapidamente
                      possível.<br /><br />
                      Nunca deve divulgar a senha da sua conta para mais ninguém.
  
                    </v-card-text>
                  </v-card>
                </div>
                <div id="partilha">
                  <v-card class="transparent">
                    <v-card-title>J. Partilha de informação</v-card-title>
                    <v-card-text>
                      As informações que recolhemos e tratamos podem ser partilhadas por nós em determinadas
                      circunstâncias.<br /><br />
                      <b>Partilhado por nós:</b> os seus dados pessoais podem ser divulgados a terceiros que prestem
                      serviços
                      específicos enquanto entidades externas subcontratadas pela IGNOTO, ou por ligação ou de acordo
                      com um contrato para prestação de serviços deste website.<br /><br />
                      As transações financeiras relacionadas ao nosso site e serviços são tratadas pelo nosso prestador
                      de serviços de pagamento, EUPAGO. Iremos partilhar os dados das transações com o nosso
                      prestador de serviços de pagamento somente na medida necessária para a finalidade de processar
                      os seus pagamentos, reembolsar tais pagamentos e gerir reclamações e consultas relacionadas a
                      tais pagamentos ou reembolsos. Usamos o Moloni - Software de faturação online na gestão de
                      contratos e para endereçar a faturação aos nossos clientes. Pode encontrar informações sobre as
                      políticas e práticas de privacidade dos nossos prestadores de serviços subcontratados em
                      <a href="https://www.eupago.pt/privacidade" target="_blank">https://www.eupago.pt/privacidade</a> e
                      <a href="https://www.moloni.pt/rgpd/" target="_blank">https://www.moloni.pt/rgpd/</a>.<br /><br />
                      Se se inscrever na nossa Newsletter ou se se registar no nosso website, armazenaremos alguns
                      dados pessoais seus, incluindo seu endereço de e-mail, endereço IP e determinadas informações
                      sobre as ligações (links) clicados nos e-mails que enviamos para si, num servidor do prestador de
                      serviços de e-mail marketing, MailJet. Nem nós nem a MailJet jamais venderemos o seu endereço
                      de e-mail ou o partilharemos com qualquer outra parte, a menos que estejamos legalmente obrigados
                      a fazê-lo. Se entrar em contato diretamente com a MailJet em relação à sua subscrição da nossa
                      Newsletter, a MailJet poderá contactá-lo diretamente; caso contrário, a MailJet nunca entrará em
                      contacto consigo. Somente funcionários autorizados da MailJet têm acesso à nossa lista de
                      assinantes. Será sempre livre de cancelar a sua assinatura da nossa newsletter, mas contando que
                      esteja registado na IGNOTO, podemos usar a MailJet para lhe enviar informações sobre a sua conta.
                      Pode encontrar informações sobre as políticas e práticas de privacidade do nosso provedor de
                      serviços de e-mail em: <a href="https://www.mailjet.com/legal/privacy-policy/"
                        target="_blank">https://www.mailjet.com/legal/privacy-policy/</a>.<br /><br />
                      Poderemos partilhar dados pessoais com terceiros se acreditarmos de boa-fé que o acesso e o uso
                      dos dados pessoais são necessários:<br /><br />
                      <ul>
                        <li>para responder a qualquer lei aplicável e / ou ordem judicial</li>
                        <li>detetar, prevenir ou, de outra forma, tratar de questões de fraude, segurança ou técnicas; e /
                          ou</li>
                        <li>para proteger os interesses, propriedades ou segurança de nós, nossos utilizadores ou o
                          público, em conformidade com a lei.</li>
                      </ul><br /><br />
                      Iremos notificá-lo sobre tal divulgação, na medida do razoavelmente possível.
                      No caso de alteração da estrutura corporativa da Dreamdeal Technologies, Unipessoal Lda.,
                      incluindo, mas sem limitação a, incorporação, fusão, venda, liquidação ou transferência substancial
                      de ativos, a Dreamdeal Technologies, Unipessoal Lda., poderá, a seu exclusivo critério, transferir,
                      vender ou ceder dados pessoais coletados via este site, incluindo, mas sem limitação, informações
                      pessoalmente identificáveis e informações agregadas sobre seus utilizadores, para uma ou mais
                      subsidiárias.<br /><br />
                      Os seus dados pessoais não serão divulgados a terceiros sem que você seja informado ou sem o
                      seu consentimento, exceto nas circunstâncias expressamente mencionadas neste documento.
                      Podemos compartilhar dados pessoais com terceiros por outros motivos que não os mencionados
                      acima, se obtivermos seu consentimento explícito para fazê-lo. Tem o direito de retirar este
                      consentimento a qualquer momento.<br /><br />
                      Poderá comunicar-nos sempre qualquer suspeita de uso indevido das suas informações pessoais
                      enviando um e-mail para data.protection@ignoto.pt e investigaremos a sua reivindicação assim que
                      possível.
                    </v-card-text>
                  </v-card>
                </div>
  
                <div id="localizacao">
                  <v-card class="transparent">
                    <v-card-title>K. Localização e transferências</v-card-title>
                    <v-card-text>
                      Poderemos precisar de partilhar as suas informações externamente com prestadores de serviços e
                      programadores subcontratados para as finalidades e atividades especificadas nesta Nota de
                      Privacidade. Consequentemente, nós e os nossos prestadores de serviços subcontratados
                      poderemos transferir dados pessoais ou acessá-los a partir de países fora do país do domicílio do
                      Utilizador. Isso poderá envolver a recolha de informações no Espaço Económico Europeu (EEA) para
                      países fora da EEA, incluindo Austrália, Canadá, Hong Kong, Japão, Nova Zelândia e Estados
                      Unidos.<br /><br />
                      Tomamos medidas para garantir que os dados pessoais dos utilizadores recebam um nível adequado
                      de proteção nos países em que os tratamos. No caso do nosso tratamento estar sujeito a qualquer
                      lei de proteção de dados da UE, e os seus dados pessoais serem transferidos do EEA para um
                      prestador de serviços para tratamento em qualquer país fora do EEA que não seja reconhecido pela
                      Comissão da UE como fornecendo um nível adequado de proteção pessoal dados, forneceremos
                      salvaguardas apropriadas por cláusulas contratuais padrão da Comissão da UE ou por qualquer
                      outra medida de salvaguarda apropriada conforme previsto na lei de proteção de dados aplicável, a
                      fim de garantir que qualquer transferência de seus dados pessoais para fora do EEE seja legal e
                      segura.
                    </v-card-text>
                  </v-card>
                </div>
                <div id="reclamacao">
                  <v-card class="transparent">
                    <v-card-title>L. Apresentar uma reclamação junto da autoridade de controlo</v-card-title>
                    <v-card-text>
                      No caso de considerar o tratamento que fazemos dos seus dados pessoais inconsistente ou em
                      incumprimento com a Lei de Proteção de Dados em vigor, poderá apresentar uma reclamação junto
                      da autoridade de controlo local.<br /><br />
                      Autoridade de Controlo em Portugal:
                      Comissão Nacional de Proteção de Dados (CNPD)
                      Morada: Rua de São Bento, nº. 148 - 3º; 1200- 821 Lisboa; ou através do e-mail: geral@cnpd.pt
  
                    </v-card-text>
                  </v-card>
                </div>
                <div id="atualizacoes">
                  <v-card class="transparent">
                    <v-card-title>M. Atualizações à Nota de Privacidade deste website</v-card-title>
                    <v-card-text>
                      Poderemos atualizar esta Nota de Privacidade a qualquer momento, se necessário, a fim de refletir
                      as alterações das nossas práticas de tratamento de dados, as leis de proteção de dados pessoais
                      ou outras normas aplicáveis. A versão atual pode ser encontrada no nosso website, juntamente com
                      a data de publicação correspondente.<br /><br />
                      Deve consultar esta Nota de Privacidade regularmente, conforme a sua aceitação ao mesmo e
                      estando vinculado às suas disposições. No entanto, iremos notificá-lo de alterações significativas a
                      esta política por e-mail. Se não concordar com alterações da Política de Privacidade, não deverá
                      continuar a aceder ao nosso website e/ou usar os nossos Serviços.
                    </v-card-text>
                  </v-card>
                </div>
                <div id="contactos">
                  <v-card class="transparent">
                    <v-card-title>N. Contactos</v-card-title>
                    <v-card-text>
                      Se tiver qualquer questão ou pedido relacionado com esta Política de Privacidade ou com as nossas
                      práticas de privacidade, poderá contactar-nos:<br /><br />
                      <ul>
                        <li>por e-mail para: data.protection@ignoto.pt</li>
                        <li>por correio para: Dreamdeal Technologies, Unipessoal Lda., rua Alfredo Keil, 371, 3E, 4150-
                          048 Porto</li>
                      </ul><br /><br />
                      Data de atualização e versão: 29.04.2024
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <div class="sroll-down text-center">
                <p class="text-uppercase mb-0">Scroll Down</p>
                <v-icon>mdi-chevron-down</v-icon>
              </div>
            </v-row>
          </div>
        </v-form>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'Policy',
    data() {
      return {
        isOpen: false,
      }
    },
    created() {
    },
    beforeCreate() {
      const el = document.body
      el.classList.remove('home')
      el.classList.remove('colorize')
    },
    methods: {
      onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (scrollTop + clientHeight >= scrollHeight) {
          document.querySelector('.policy-content').className += ' active'
        } else {
          document.querySelector('.policy-content').classList.remove('active')
        }
      },
      toggleMenu(e) {
        e.preventDefault()
        e.stopPropagation()
        this.isOpen = !this.isOpen
      }
    }
  }
  </script>
  
  <style lang="scss">
  .vue-slider.policy .vert-custom-dot {
    border-color: #0837fb;
  }
  
  .contact-center {
    font-size: 18px;
  
    span {
      color: #0837fb;
    }
  }
  
  .page-title {
    font-family: "Bebas Neue", monospace;
    font-size: 40px;
    color: #0837fb;
  }
  
  .sroll-down {
    position: absolute;
    bottom: 0;
    color: #FFFFFF;
    font-weight: bold;
    width: 105%;
    z-index: 5;
    padding-bottom: 25px !important;
    padding-top: 25px;
    transition: 0.4s all;
    opacity: 1;
  
    img {
      width: 25px;
    }
  
    &::after {
      background: rgb(0, 0, 0);
      background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6615021008403361) 18%, rgba(0, 0, 0, 1) 37%, rgba(0, 0, 0, 1) 77%);
      background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6615021008403361) 18%, rgba(0, 0, 0, 1) 37%, rgba(0, 0, 0, 1) 77%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6615021008403361) 18%, rgba(0, 0, 0, 1) 37%, rgba(0, 0, 0, 1) 77%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
      content: "";
      position: absolute;
      width: 100%;
      top: -45px;
      height: 148%;
      left: 0;
      z-index: -1;
    }
  }
  
  #policy-form {
    max-width: 1000px;
    padding: 2rem 5rem 0 5rem;
    position: relative;
    border-radius: 1rem;
  
    // margin-top: 7rem;
    .policy-content {
      overflow-y: auto;
      height: 85vh;
      scrollbar-width: none;
      -ms-overflow-style: none;
  
      &::-webkit-scrollbar {
        display: none;
      }
  
      &.active {
        .sroll-down {
          opacity: 0;
        }
      }
    }
  
    .form-content {
      position: relative;
      z-index: 5;
    }
  
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: -5px;
      background: #000;
      border-radius: 0.8rem;
    }
  
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      margin: -13px;
      background: linear-gradient(to bottom, #FFFFFF, #000000);
      border-radius: 1rem;
    }
  }
  
  @media only screen and (max-width : 1600px) {
    #policy-form {
      max-width: 850px;
      padding: 2rem 3rem 0 3rem;
      margin-top: 2rem;
  
      h3 {
        font-size: 1rem;
        margin: 0;
      }
    }
  }
  
  @media only screen and (max-width : 1600px) {
    #policy-form {
      max-width: 850px;
      padding: 2rem 3rem 0 3rem;
      margin-top: 2rem;
  
      h3 {
        font-size: 1rem;
        margin: 0;
      }
    }
  }
  
  @media only screen and (max-width: 1263px) {
    #policy-form {
      padding: 40px;
      padding-top: 80px;
    }
  }
  </style>